import axios from 'axios';

const BASE_URL = process.env.VUE_APP_BASE_URL_MERCURY;

const token = sessionStorage.getItem('token');

export default axios.create({
    baseURL: BASE_URL,
    headers: {
        Authorization: `Bearer ${token}`,
        'Api-key': 'Yq7Z343jGEtBY6JuSh5D9Uz8JtiIXGg1IXeEBgpJ',
    },
    timeout: 0,
});
