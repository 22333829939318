const { default: Mercury } = require('@/service/base/mercury');

class GroupRequest extends Mercury {
    async getAllGroups() {
        return this.fetch({
            method: 'GET',
            url: 'register/group',
        });
    }

    async geGroupById(groupId) {
        return this.fetch({
            method: 'GET',
            url: `register/group/${groupId}`,
        });
    }

    async createNewGroup(data) {
        return this.fetch({
            method: 'POST',
            data,
            url: 'register/group',
        });
    }

    async updateGroup(data) {
        return this.fetch({
            method: 'PUT',
            data,
            url: `register/group/${data.id}`,
        });
    }
}

export default new GroupRequest();
